import React from "react"
import Helmet from "react-helmet"

import PageLink from "../components/pageLink"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./archives.scss"
import {
  onBackToMain,
  onSetExitTo,
  BASE_TRANSITION_LENGTH,
} from "../transitions"

const ArchivesPage = ({ transitionStatus }) => (
  <>
    <Helmet
      bodyAttributes={{
        class: "page archives",
      }}
      key="helmet"
    />
    <Layout transitionStatus={transitionStatus} page="archives">
      <SEO title="Archive" />
      <PageLink
        to="/"
        className="transitionLink transitionLink--home"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          trigger: args => onSetExitTo("main", args),
        }}
        entry={{ length: 0, trigger: args => onBackToMain("archives", args) }}
      ></PageLink>
      <PageLink
        to="/archive/animal-house"
        className="transitionLink transitionLink--archive transitionLink--animalHouse"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Animal House
      </PageLink>
      <PageLink
        to="/archive/animation"
        className="transitionLink transitionLink--archive transitionLink--animation"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Animation
      </PageLink>
      <PageLink
        to="/archive/blow-up"
        className="transitionLink transitionLink--archive transitionLink--blowUp"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Blow Up
      </PageLink>
      <PageLink
        to="/archive/carry-on-laughing"
        className="transitionLink transitionLink--archive transitionLink--carryOnLaughing"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Carry on Laughing
      </PageLink>
      <PageLink
        to="/archive/celebrity"
        className="transitionLink transitionLink--archive transitionLink--celebrity"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Celebrity
      </PageLink>
      <PageLink
        to="/archive/cheers"
        className="transitionLink transitionLink--archive transitionLink--cheers"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Cheers
      </PageLink>
      <PageLink
        to="/archive/the-color-of-money"
        className="transitionLink transitionLink--archive transitionLink--theColorOfMoney"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        The Color of Money
      </PageLink>
      <PageLink
        to="/archive/dressed-to-kill"
        className="transitionLink transitionLink--archive transitionLink--dressedToKill"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Dressed to Kill
      </PageLink>
      <PageLink
        to="/archive/fallen"
        className="transitionLink transitionLink--archive transitionLink--fallen"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Fallen
      </PageLink>
      <PageLink
        to="/archive/food-inc"
        className="transitionLink transitionLink--archive transitionLink--foodInc"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Food, Inc.
      </PageLink>
      <PageLink
        to="/archive/the-football-factory"
        className="transitionLink transitionLink--archive transitionLink--theFootballFactory"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        The Football Factory
      </PageLink>
      <PageLink
        to="/archive/the-game"
        className="transitionLink transitionLink--archive transitionLink--theGame"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        The Game
      </PageLink>
      <PageLink
        to="/archive/history-of-the-world"
        className="transitionLink transitionLink--archive transitionLink--historyOfTheWorld"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        History of the World
      </PageLink>
      <PageLink
        to="/archive/kids"
        className="transitionLink transitionLink--archive transitionLink--kids"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Kids
      </PageLink>
      <PageLink
        to="/archive/the-last-action-hero"
        className="transitionLink transitionLink--archive transitionLink--theLastActionHero"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        The Last Action Hero
      </PageLink>
      <PageLink
        to="/archive/metropolis"
        className="transitionLink transitionLink--archive transitionLink--metropolis"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Metropolis
      </PageLink>
      <PageLink
        to="/archive/naked"
        className="transitionLink transitionLink--archive transitionLink--naked"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Naked
      </PageLink>
      <PageLink
        to="/archive/over-the-top"
        className="transitionLink transitionLink--archive transitionLink--overTheTop"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Over the Top
      </PageLink>
      <PageLink
        to="/archive/the-paper"
        className="transitionLink transitionLink--archive transitionLink--thePaper"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        The Paper
      </PageLink>
      <PageLink
        to="/archive/parenthood"
        className="transitionLink transitionLink--archive transitionLink--parenthood"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Parenthood
      </PageLink>
      <PageLink
        to="/archive/phonebooth"
        className="transitionLink transitionLink--archive transitionLink--phonebooth"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Phonebooth
      </PageLink>
      <PageLink
        to="/archive/the-public-eye"
        className="transitionLink transitionLink--archive transitionLink--thePublicEye"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        The Public Eye
      </PageLink>
      <PageLink
        to="/archive/the-running-man"
        className="transitionLink transitionLink--archive transitionLink--theRunningMan"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        The Running Man
      </PageLink>
      <PageLink
        to="/archive/shall-we-dance"
        className="transitionLink transitionLink--archive transitionLink--shallWeDance"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Shall We Dance
      </PageLink>
      <PageLink
        to="/archive/the-sound-of-music"
        className="transitionLink transitionLink--archive transitionLink--theSoundOfMusic"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        The Sound of Music
      </PageLink>
      <PageLink
        to="/archive/look-whos-talking"
        className="transitionLink transitionLink--archive transitionLink--lookWhosTalking"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Look Who's Talking
      </PageLink>
      <PageLink
        to="/archive/sweet-charity"
        className="transitionLink transitionLink--archive transitionLink--sweetCharity"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Sweet Charity
      </PageLink>
      <PageLink
        to="/archive/this-sporting-life"
        className="transitionLink transitionLink--archive transitionLink--thisSportingLife"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        This Sporting Life
      </PageLink>
      <PageLink
        to="/archive/traffic"
        className="transitionLink transitionLink--archive transitionLink--traffic"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Traffic
      </PageLink>
      <PageLink
        to="/archive/weird"
        className="transitionLink transitionLink--archive transitionLink--weird"
        exit={{
          length: BASE_TRANSITION_LENGTH,
          zIndex: 0,
          trigger: args => onSetExitTo("archive", args),
        }}
        entry={{ length: BASE_TRANSITION_LENGTH, zIndex: 10 }}
      >
        Weird
      </PageLink>
    </Layout>
  </>
)

export default ArchivesPage
